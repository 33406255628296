
<template>
  <layout>
    <v-card>
        <v-card-title>
            <span class="title font-weight-light">บริหารแผน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
          <v-icon
                small
                left
            >
                mdi-book-open-variant
            </v-icon>
            <span class="subtitle-1">ข้อมูล</span>
        </v-card-title>
        <v-divider class="mx-4" />
        
        <v-container class="body-2">
            <!-- <v-row>
                <v-col cols="3">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        label="ค้นหาจากชื่อ"
                        outlined
                        dense
                        v-model="keywordName"
                    ></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        label="ค้นหาจากนามสกุล"
                        outlined
                        dense
                        v-model="keywordLastName"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn outlined color="green" @click="search()">ค้นหา</v-btn>
                </v-col>
            </v-row> -->
            <v-btn depressed color="primary" to="/plan/add" class="mb-2">
                + เพิ่มข้อมูลแผน
            </v-btn>
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-data-table
                            :headers="headers"  
                            :items="planData"
                        >
                        <template v-slot:header="{ }" >
                          <thead>
                            <tr>
                              <th colspan="2"></th>
                              <th colspan="4" class="text-center">ผลการประเมินแผน</th>
                              <th colspan="1"></th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item.link_file="{ item }">
                          <a :href="item.link_file" target="_blank">ไฟล์แนบ</a>
                        </template>
                        <template v-slot:item.quarter1data="{ item }">
                          {{ item.quarter1 }}
                          {{ item.quarter1_remark }}
                        </template>
                        <template v-slot:item.quarter2data="{ item }">
                          {{ item.quarter2 }}
                          {{ item.quarter2_remark }}
                        </template>
                        <template v-slot:item.quarter3data="{ item }">
                          {{ item.quarter3 }}
                          {{ item.quarter3_remark }}
                        </template>
                        <template v-slot:item.quarter4data="{ item }">
                          {{ item.quarter4 }}
                          {{ item.quarter4_remark }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div v-if="storeUser.user_group == 4">
                            <v-btn :to="'/plan/edit/' + item.id" depressed class="mr-2 orange darken-2 white--text">
                                แก้ไข
                            </v-btn>

                            <v-btn @click="deleteData(item.id)" depressed class="mr-2 red darken-2 white--text">
                                ลบ
                            </v-btn>
                          </div>
                        </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-container>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    data: () => ({
        storeUser: null,
        headers: [
            {
                text: "ชื่อแผน",
                value: "plan_name"
            },
            {
                text: "ไฟล์",
                value: "link_file"
            },
            {
                text: "ไตรมาส1",
                value: "quarter1data"
            },
            {
                text: "ไตรมาส2",
                value: "quarter2data"
            },
            {
                text: "ไตรมาส3",
                value: "quarter3data"
            },
            {
                text: "ไตรมาส4",
                value: "quarter4data"
            },
            {
                text: "จัดการ",
                value: "actions",
                align: "center"
            }
        ],
        planData: [],
        keywordName: '',
        keywordLastName: '',
    }),
    created() {
      this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData();
      this.$store.commit('setLoading', false)
    },
    methods: {
      async search() {
        try {
          const req = {
            params: {
              firstname: this.keywordName,
              lastname: this.keywordLastName,
            }
          }
          const res = (await Api().get("staff/search", req)).data;

          if (res.status == 'success') {
            this.staffData = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async getData() {
        try {
          const res = (await Api().get("plan")).data;

          console.log('data', res);

          if (res.status == 'success') {
            this.planData = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async deleteData(id){
        if (await this.$refs.confirm.open('ยืนยัน', 'ต้องการลบข้อมูลหรือไม่', { color: 'primary', noconfirm: false })) {
          this.$store.commit('setLoading', true)

          const req = {}

          let res = (await Api().post("/plan/del/" + id, req)).data;

          if (res.status == 'success') {
            this.$store.commit('setLoading', false)
            await this.getData();
          }else{
            this.$store.commit('setLoading', false)
          }
        }
      },
    }
};
</script>